import React from 'react';
import Slider from 'react-slick';
import './Team.css';
import TeamMember from './TeamMember';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface TeamProps {
  members: { image: string; name: string }[];
}

const Team: React.FC<TeamProps> = ({ members }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="team">
      <Slider {...settings}>
        {members.map((member, index) => (
          <TeamMember key={index} image={member.image} name={member.name} />
        ))}
      </Slider>
    </div>
  );
};

export default Team;
