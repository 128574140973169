import React from "react";
import "../commonComponent/commonContent.scss";
import Webcodeicon from "../../asset/img/webcodetags.svg";
import search from "../../asset/img/search.png";
import ecom from "../../asset/img/e-commerceicon.svg";
import socialmedia from "../../asset/img/digitalicon.svg";
import Custom from "../../asset/img/customicon.svg";
import target from "../../asset/img/target.png";
import logodesign from "../../asset/img/logodesign.png";
import mobiledevolment from "../../asset/img/androidicon.svg";
import UIUX from "../../asset/img/uiuxicon.svg";

function Getstart(){
    return(
        <div className="maindiv" id="maindiv">
                <div className="head">
                    <h2>What we do?</h2>
                    <p><span>Prajnan Software</span> is the one stop solution for all your IT needs.<br></br>
                        We offer industry standard services in</p>
                </div>
                <div className="bodycontanier backgroundimg2">
                    <div className="box">
                        <div className="icon">
                            <img src={Webcodeicon} alt="tag-icon-arrows"></img>
                        </div>
                        <h1>Web development</h1>
                        <p>Quality web services for everyone's <br></br>online presence.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={mobiledevolment} alt="mobiledevolment"></img>
                        </div>
                        <h1>Android and IOS app development</h1>
                        <p>Everyone is on mobile devices. It's easy to get your own app now.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={UIUX} alt="mobiledevolment"></img>
                        </div>
                        <h1>UI/UX design and development</h1>
                        <p>Design matters! We're the digital artists <br></br> for your needs.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={socialmedia} alt="socialmedia"></img>
                        </div>
                        <h1>Social media marketing</h1>
                        <p>Go viral with us.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={search} alt="search"></img>
                        </div>
                        <h1>Search Engine Optimization</h1>
                        <p>We make your digital presence to be<br></br>found online.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={Custom} alt="setting"></img>
                        </div>
                        <h1>Custom Software</h1>
                        <p>We can understand your software <br></br>needs better.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={ecom} alt="e-com"></img>
                        </div>
                        <h1>E-commerce solution</h1>
                        <p>Owning a digital store was never<br></br>this easy.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={logodesign} alt="logodesign"></img>
                        </div>
                        <h1>Logo and brand design kit for new businesses</h1>
                        <p>Branding solutions for your dream project.</p>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <img src={target} alt="target"></img>
                        </div>
                        <h1>Lead generation service</h1>
                        <p>Looking for new customers? Our lead<br></br>service can help you!.</p>
                    </div>
                </div>
        </div>
    )
}
export default Getstart;