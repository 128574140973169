import React from 'react';
import './starComponent.scss';

interface StarRatingProps {
  rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <span key={i} className={i < rating ? 'star filled' : 'star'}>
        &#9733; 
      </span>
    );
  }
  return <div>{stars}</div>;
};

export default StarRating;
