import { BaseContext } from './BaseContext';
import { useState } from 'react';

export const BaseContextProvider = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [userData, setUserData] = useState({ 
    email: 'user@gmail.com',
    password: '456',
  });
  const openModal = (e:any) => {
    setIsModalOpen(true);
  };

  const closeModal = (e:any) => {
    if(e.target.className === "modal" || e.target.className === "close"){
        setIsModalOpen(false);
    }
  };
  
  const openProfile = (e:any) => {
    setIsProfileModalOpen(true);
    console.log("isProfileModalOpen" ,isProfileModalOpen);
  };
  const closeProfile = (e:any) => {
    if(e.target.className === "profilemodal-overlay" || e.target.className === "Profileclose"){
      setIsProfileModalOpen(false);
    }
  };
  const handleLoad = () => {
    setLoading(false);
};
    const alldata = {
        isModalOpen,
        setIsModalOpen,
        openModal,
        closeModal,
        isSignIn,
        setIsSignIn,
        isLogin,
        setIsLogin,
        openProfile,
        closeProfile,
        isProfileModalOpen,
        setIsProfileModalOpen,
        userData,
        setUserData,
        loading,
        setLoading,
        handleLoad
    };
    // useEffect(() => {
    //   // Check localStorage for the login state when the component mounts
    //   const storedLoginState = localStorage.getItem('isLogin');
    //   if (storedLoginState) {
    //     setIsLogin(JSON.parse(storedLoginState));
    //   }
    // }, []);
    return (
        <BaseContext.Provider value={alldata}>
          {props.children}
        </BaseContext.Provider>
      );
};




