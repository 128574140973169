import React, { useContext, useState } from 'react';
import SignIn from './SignIn'; // Your SignIn component
import SignUp from './signUp'; // Your SignUp component
import './Modal.scss'; // SCSS for modal component
import { BaseContext } from '../baseContext/BaseContext';

const Modal = (props: any) => {
  const {
    isModalOpen,
    closeModal,
    isSignIn,
    setIsSignIn,
    setUserData
  } = useContext(BaseContext);

  const handleToggle = () => {
    setIsSignIn(!isSignIn);
  };

 

  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <div className="closediv"><span className="close" onClick={closeModal}>&times;</span></div>
            {isSignIn ? <SignIn setUserData={setUserData}/> : <SignUp />}
            <p onClick={handleToggle} className="toggle-link">
              {isSignIn ? 'Need an account? Sign Up' : 'Already have an account? Sign In'}
            </p>
          </div>
        </div>
      )}
      
    </>
  );
};

export default Modal;
