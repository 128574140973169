import React, { useState, useEffect } from 'react';
import './FloatingScrollButton.scss'; 

const FloatingScrollButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) { 
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    };

    return (
        <div>
            {isVisible && (
                <button className="floating-button" onClick={scrollToTop}>
                    &#8682;
                </button>
            )}
        </div>
    );
};

export default FloatingScrollButton;
