import React, { useContext, useState } from "react";
import "./CareersComponent.scss";
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import Header from "../Header/HeaderComponent";
import Footer from "../Footer/FooterComponent";
import careerimage from "../asset/img/careerimage.svg";
import Back from "../asset/img/back.png";
import Slide1 from "../asset/img/AIimage1.svg";
import Slide2 from "../asset/img/AIimage2.svg";
import Slide3 from "../asset/img/AIimage3.svg";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BaseContext } from "../baseContext/BaseContext";
import { Helmet } from "react-helmet";
import PositionPage from "./Jobpost/PositionPage";
interface Roleslist {
  value: string;
  label: string;
}
function Careers(){
  const {
    handleLoad,
    loading
  } = useContext(BaseContext);
  
const slideImages = [
  {
    url: Slide1,
    // caption: "“When AI meets humans: Crafting tomorrow's innovations today”"
  },
  {
    url: Slide2,
    // caption: 'Slide 2'
  },
  {
    url: Slide3,
    // caption: 'Slide 3'
  },
];
  
const spanStyle = {
  display: 'flex',
  color: '#013979',
 
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: '100% 100%',
  height: '100%',
  width: '100%',
  margin: '32px 0',
  fontsize: '24px',
  fontweight: 'bold',
}
let animationDirection: 'forward' | 'backward' = 'forward';
const properties = {
  duration: 2000, // Set your animation speed in milliseconds
  transitionDuration: 400, // Set your transition duration in milliseconds
  infinite: true,
  indicators: false,
  arrows: false,
  onChange: (oldIndex: number, newIndex: number) => {
    if (newIndex === slideImages.length - 1) {
      // If the last slide is reached, change the animation direction to 'backward'
      animationDirection = 'backward';
    } else if (oldIndex === slideImages.length - 1 && newIndex === 0) {
      // If moving from the last slide to the first slide, change the animation direction to 'forward'
      animationDirection = 'forward';
    }
  }
}
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [dob, setDOB] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [resumeFile, setResumeFile] = useState<File | undefined>();

    // const openModal = () => {
    //   setIsOpen(false);
    // };
  
    // const closeModal = (e:any) => {
    //   if(e.target.className === "close-btn"){
    //     console.log("close",e.target.className);
    //     setIsOpen(false);
    //   }
    // };
    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = event.target.files?.[0];
    //     if(file){
    //         setResumeFile(file);
    //     }
        
    //   };
    //   const resetForm = () => {
    //     setFullName('');
    //     setEmail('');
    //     setDOB('');
    //     setPhoneNumber('');
    //     setSelectedCourse('');
    //     setResumeFile(undefined);
    //   };
    // const handleSubmit = (event :any) => {
    //     event.preventDefault();
    //     setIsOpen(false);
    //     resetForm();
    //   };
    
    //   const handleCourseChange = (event:any) => {
    //     setSelectedCourse(event.target.value);
    //   };
    //   const handleDOBChange = (event:any) => {
    //     setDOB(event.target.value);
    //   };
    //   const handlePhoneNumberChange = (event:any) => {
    //     setPhoneNumber(event.target.value);
    //   };
    //   const handleNameChange = (event:any) => {
    //     setFullName(event.target.value);
    //   };
    //   const handleEmailChange = (event:any) => {
    //     setEmail(event.target.value);
    //   };
    //   const openGoogleForm = () => {
    //     const formUrl = 'https://forms.gle/pqhYx1wWafnu3XdF9'; 
    //     window.open(formUrl, '_blank'); 
    //   };
    const [isExploreClick, setIsExploreClick] = useState(false);
    const [selectedOption, setSelectedOption] = React.useState<string[]>([]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const handleChange = (event: SelectChangeEvent<typeof selectedOption>) => {
      const {
        target: { value },
      } = event;
      setSelectedOption(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const locationList = [
      'Bengaluru',
      'Hydrabad',
      'Chennai',
    ];
    const Roleslist: Roleslist[] = [
      { label: 'Full Stack Interns', value:'Full Stack Interns' },
      { label: 'Back-End Engineer', value:'Back-End Engineer' },
      { label: 'Full Stack Engineer', value:'Full Stack Engineer' },
      { label: 'Front-End Engineer', value:'Front-End Engineer' },
    ];
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Handle search logic here
      console.log('Search Query:', event.target.value);
    };
    const openGoogleForm = () => {
      const formUrl = 'https://forms.gle/pqhYx1wWafnu3XdF9'; 
      window.open(formUrl, '_blank'); 
    };

    const internDetails = {
      title: 'Join Our Team as an Intern',
      responsibilities: [
        'Develop and maintain web applications.',
        'Collaborate with the development team to design new features.',
        'Debug and troubleshoot issues in a timely manner.',
        'Participate in code reviews and team meetings.',
      ],
      eligibility: [
        'Currently pursuing a degree in Computer Science or related field.',
        'Basic understanding of web development or Android development.',
        'Strong problem-solving skills.',
        'Excellent communication and teamwork abilities.',
      ],
      roles: ['Web Development', 'Android Application Development', 'Backend Development', 'Full-Stack Development', 'UI/UX Design'],
      googleFormLink: 'https://forms.gle/pqhYx1wWafnu3XdF9',
    };
  
    const memberDetails = {
      title: 'Join Our Team as a Member of Technical Staff',
      responsibilities: [
        'Design, develop, and maintain software applications.',
        'Collaborate with cross-functional teams to define, design, and ship new features.',
        'Troubleshoot and resolve software defects and issues.',
        'Participate in code reviews and provide constructive feedback.',
        'Ensure the performance, quality, and responsiveness of applications.',
      ],
      eligibility: [
        'Bachelor\'s degree in Computer Science or related field.',
        'Proven experience in software development.',
        'Strong understanding of programming languages such as Java, C++, Javascript, React,  or Python.',
        'Excellent problem-solving skills.',
        'Strong communication and teamwork abilities.',
      ],
      roles: ['Frontend Development', 'Backend Development','Android Application Development', 'Full-Stack Development', 'DevOps Engineering', 'Quality Assurance'],
      googleFormLink: 'https://forms.gle/Zy7uBL5t7aXkT1Nw5',
    };

    return(
        <>
          <Helmet>
                <title>Explore Exciting Opportunities | Prajnan Software Careers</title>
          </Helmet>
            <Header></Header>
            {!isExploreClick ? (<div className="CareerDiscBox">
              <div className="careerMainContainer">
              <div className="careerHeader">
                <div className="leftContainer">
                  <div className="topContainer">
                    <div className="backbutton">
                      <a href="/home"><img src={Back} alt="arrow" /></a>
                  </div>
                    <div className="careertitle">Careers at Prajnan</div>
                    <div className="description">7 roles in 1 location</div>
                  </div>
                  <div className="careerbottomContainer">
                    <div className="button" onClick={()=>setIsExploreClick(true)}>Explore roles</div>
                  </div>
                </div>
                <div className="rightContainer">
                {loading && (
                  <div className="Careerimage">
                    <div className="loaderdiv p1">
                      <div className="loader"></div>
                    </div>
                    <div className="loaderdiv p2">
                      <div className="loader"></div>
                    </div>
                    <div className="loaderdiv p3">
                      <div className="loader"></div>
                    </div>
                  </div>
                )}
                <img src={careerimage} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} alt="AI-image" />
                </div>
              </div>
              <div className="CareerContent">
                <h2>Join Our Dynamic Team of Innovators</h2>
                <p>Are you passionate about technology and eager to make a meaningful impact in the world of IT services? <span>Prajnan Software</span> is on the lookout for talented individuals to join our growing team. As a leading player in the industry, we are dedicated to fostering a culture of innovation, collaboration, and continuous learning.</p>
                <h2>Why Choose <span>Prajnan Software</span> ?</h2>
                <ul>
                  <li>Innovative Projects: Be at the forefront of cutting-edge technologies and work on projects that push the boundaries of what's possible.</li>
                  <li>Collaborative Environment: Join a team of like-minded professionals who thrive on collaboration and mutual support.</li>
                  <li>Career Growth: We believe in nurturing talent. Unlock your full potential through our career development programs and continuous learning initiatives.</li>
                  <li>Global Impact: Contribute to projects with global reach and see the tangible impact of your work on a larger scale.</li>
                </ul>
              </div>
            </div> 
            <div className="slide-container">
                <Slide {...properties}>
                  {slideImages.map((slideImage, index)=> (
                  <div key={index}>
                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}></div>
                  </div>
                  ))} 
                </Slide>
              </div>
            </div>):(
                <div className="CareerRolesContainer">
                  <div className="careerRolesHeader">
                    <div className="backbutton">
                      <img src={Back} alt="arrow" onClick={()=>setIsExploreClick(false)}/>
                    </div>
                    {/* <div className="Rolesheading">
                      <h2>We have 1 open position</h2>
                      <p>We’ve got an ambitious road ahead. Join our team and help us shape the future.</p>
                    </div> */}
                  </div>
                  <div className="careerRolesDetails">
                    {/* <div className="careersearchresult">
                    <div className="careerFilter">
                      <div className="careerLocation">
                      <FormControl>
                        <InputLabel  id="demo-multiple-name-label">Location</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedOption}
                          onChange={handleChange}
                          input={<OutlinedInput label="Location" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          >
                          {locationList.map((location) => (
                          <MenuItem key={location} value={location}>
                          <Checkbox checked={selectedOption.indexOf(location) > -1} />
                          <ListItemText primary={location} />
                          </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      </div>
                      <div className="careerDept">

                      </div>
                    </div>
                    <div className="careerList">
                      <div className="search">
                      <Autocomplete
                      options={Roleslist.map((option) => option.value)}
                      getOptionLabel={(Roleslist) => Roleslist}
                      renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Search for the role"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                      )}
                      />
                      </div>
                    </div>
                    </div> */}
                    {/* <div className="jobsdisc">
                    <div className="joblist" onClick={openGoogleForm}>
                    <h3>For Internship Click Here </h3>
                    <p>OR Contact us at <a href="mailto:talentacquisition@prajnansoftwares.com">talentacquisition@prajnansoftwares.com</a> for details.</p>
                  </div>
                    </div> */}
                   
                    <PositionPage {...internDetails} />
                    <PositionPage {...memberDetails} />
                  </div>
                </div>
              )}
            <Footer></Footer>
        </>
    )
}
export default Careers;