import React from "react";
import "./commonContent.scss";
import cc1 from "../../asset/img/cc1.svg";

function commonContent1(){
    return(
        <div className="commoncontentbox" >
            <div className="image">
                <img src={cc1} alt="illu"></img>
            </div>
            <div className="text backgroundimg1"> 
                <h2>We are an IT Solution company that specializes in developing.</h2>
                <p>We are a bunch of young and energetic entrepreneurs with passion for technology and internet services from past decade. We understand and believe internet presence as biggest asset for any business in this 21st century. A passionate and experienced team with goal of providing best and affordable IT services to the masses.</p>
            </div>
        </div>
    )
}
export default commonContent1;