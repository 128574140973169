import React from 'react';
import './Waviy.css';

interface WaviyProps {
  text: string;
}

const Waviy: React.FC<WaviyProps> = ({ text }) => {
  return (
    <div className="waviy">
      {Array.from(text).map((char, index) => (
        <span key={index} style={{ '--i': index + 1 } as React.CSSProperties}>
          {char}
        </span>
      ))}
    </div>
  );
};

export default Waviy;
