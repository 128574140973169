import React, { useEffect, useRef, useState } from "react";
import "./FooterComponent.scss";
import Instagram from "../asset/img/instagram.png";
import Facebook from "../asset/img/facebook.png";
import Linkdin from "../asset/img/linkedin.png";
import Twitter from "../asset/img/twitter.png";

function Footer(){
    const footerRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
              } else {
                setIsVisible(false);
              }
            });
          },
          { threshold: 0.1 }
        );
    
        if (footerRef.current) {
          observer.observe(footerRef.current);
        }
    
        return () => {
          if (footerRef.current) {
            observer.unobserve(footerRef.current);
          }
        };
      }, []);
    return(
        <div className="footermainContainer">
            <div className="topContainer" >
                <div className="t1">
                    <div className="left">Let&apos;s Start your project, <a href="mailto:info@prajnansoftwares.com">Mail Us</a></div>
                    <div className="right">contactus@prajnansoftwares.com</div>
                </div>
                <div className="line"></div>
                <div ref={footerRef} className={`t2 ${isVisible ? 'visible' : ''}`}>
                    <div className="t2A">
                    <div className="box">
                        <p className="title">PRAJNAN SOFTWARE</p>
                        <p className="text">Prajnan Software private limited <br></br> Hebbal,Bengaluru - 560092 <br></br>Email : info@prajnansoftwares.com<br></br>Mobile : +91 7022478822 </p>
                    </div>
                    <div className="box">
                        <div className="title">Services</div>
                        <div className="text"><a href="#maindiv"><ul>
                            <a href="/webservice"><li>Web Development</li></a>
                            <li>UI/UX design and development</li>
                            <li>Logo and brand design kit for new businesses</li>
                            <li>App Development</li>
                            </ul>
                            </a></div>
                    </div>
                    <div className="box">
                        <div className="title">Useful Links</div>
                        <div className="text"> <ul>
                            <li><a href="/home">Home</a></li>
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/careers">Careers</a></li>
                            <li><a href="/contactus">Contact Us</a></li>
                        </ul></div>
                    </div>
                    </div>
                    <div className="t2B">
                        <div className="circle">
                            <a href="https://www.instagram.com/prajnan_software?igsh=MWxlYnVlMDFsa3JhbA==" target="_blank"><img src={Instagram} alt="icons"></img></a>
                        </div>
                        <div className="circle">
                            <a href="https://www.facebook.com/profile.php?id=61555290569819" target="_blank"><img src={Facebook} alt="icons"></img></a>
                        </div>
                        <div className="circle">
                            <a href="https://www.linkedin.com/company/prajnan-software-pvt-ltd" target="_blank"><img src={Linkdin} alt="icons"></img></a>
                        </div>
                        <div className="circle">
                            <a href="https://twitter.com/PrajnanSoftware" target="_blank"><img src={Twitter} alt="icons"></img></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="bottomContainer">
                <p>Copyright ©2024 Prajnan Software Pvt. Ltd. All rights reserved.</p>
            </div>
        </div>
    )
}
export default Footer;