import React from 'react';
import './ImageGrid.css';

interface ImageGridProps {
  images: string[];
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  return (
    <div className="image-grid">
      <div className="row">
        {images.slice(0, 3).map((image, index) => (
          <div key={index} className="image-item">
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="row">
        {images.slice(3, 5).map((image, index) => (
          <div key={index} className="image-item">
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
