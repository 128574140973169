import React from 'react';
import './BlackBoxComponent.scss';

interface BlackBoxProps {
  buletext: string;
  orangetext: string;
  imageUrl: string;
}

const BlackBox: React.FC<BlackBoxProps> = ({ buletext, orangetext, imageUrl }) => {
  return (
    <div className="blackdiv">
        <img src={imageUrl} alt="HomeBlackimage" />
        <div className="content">{buletext} <span> {orangetext}</span></div>
        <a href="/contactus"><div className="callbutton">Schedule a call</div></a>
    </div>
  );
};

export default BlackBox;
