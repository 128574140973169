import React, { useContext, useState } from 'react';
import './SignIn.scss'; // SCSS for SignIn component
import { BaseContext } from '../baseContext/BaseContext';

const SignIn = (props:any) => {

  const{
    setIsModalOpen,
    setIsLogin,
    userData,
    setUserData,
  } = useContext(BaseContext);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const clearUserData = () => {
    localStorage.removeItem('isLogin');
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const email = formData.get('email') as string;
    console.log("formdata",formData.get('email') as string);
    console.log("email:",email);
    fetch('Login.php',{
      method: 'POST',
      body: formData,
    })
    .then(response => response.text())
    .then(async data => {
      console.log(data);
      const user = async () =>{
        try{
          setUserData({ ...formData });
        }catch{
          console.log("error in set user");
        }
      }
      await user();
      if(data.includes('Login successful')){
        alert("Login successful");
        setFormData({
          email: '',
          password: ''
        });
        
        // localStorage.setItem('isLogin', JSON.stringify(true));
        // const sessionTimeout = 30 * 60 * 1000; 
        // setTimeout(() => {
        // clearUserData();
        // }, sessionTimeout);
        setIsLogin(true);
        setIsModalOpen(false);
      }else{
        alert('Invalid username/email or password.');
      }
    })
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit} action="Login.php" method='POST'>
      <input
        type="email"
        name='email'
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="password"
        name='password'
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
        required
      />
      <button type="submit">Sign In</button>
    </form>
  );
};

export default SignIn;
