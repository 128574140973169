import React from "react";
import "./commonContent.scss";
import profile from "../../asset/img/profile.png";
import profile2 from "../../asset/img/profile 2.svg";
import TestimonialSlider from "./slider/Slider";
const testimonials = [
    {
      profilePic: profile,
      name: 'Business Owner',
      location: 'India',
      rating: 5,
      text: 'Your one stop destination for anything digital. We are here to serve your organizational and   personal needs to digital services with atmost importance to quality and service.',
    },
    {
      profilePic: profile2,
      name: 'Arun T N',
      location: 'India',
      rating: 4,
      text: "Thank you for developing our restaurant website. We appreciate the responsive design and user-friendly interface, making it easy for customers to navigate. The visually appealing layout and smooth functionality contribute to a positive user experience. However, we suggest optimizing loading times and enhancing SEO efforts for better visibility. Overall, we're satisfied with your service and look forward to potential improvements.",
    },
    {
        profilePic: profile,
        name: 'Suchethan',
        location: 'India',
        rating: 5,
        text: "Thank you for the exceptional custom billing software. It's tailored perfectly to our needs, with a user-friendly interface and reliable performance. Seamless integration, insightful analytics, and responsive support make it a valuable asset. Looking forward to continued collaboration and future enhancements.",
    },
    {
        profilePic: profile2,
        name: 'Western English School',
        location: 'India',
        rating: 4,
        text: "Thank you for the recent updates to our school website. The visual enhancements, improved navigation, and responsive design greatly enhance the user experience. Your timely updates, responsive support, and incorporation of feedback are appreciated.",
    },
    {
        profilePic: profile,
        name: 'Sri laxmi finanace',
        location: 'India',
        rating: 4,
        text: "Thank you for developing the fintech application for Sri Laxmi Finance. Here's some feedback: simplify UI/UX, enhance security, offer customization, improve performance, include feedback feature, and ensure ongoing support and updates.",
    },
    // {
    //     profilePic: profile2,
    //     name: 'Dijolng Inc',
    //     location: 'Finland',
    //     rating: 5,
    //     text: "We appreciate your efforts in developing APIs for our Dijolng products. In providing feedback, we emphasize the importance of seamless integration, comprehensive documentation, scalability, reliability, robust security measures, flexibility for future updates, and the inclusion of a feedback mechanism. Your attention to these aspects will contribute significantly to the success of our collaboration.",
    // },
    {
        profilePic: profile,
        name: 'Finlogy Inc',
        location: 'Italy',
        rating: 4,
        text: "Thank you for developing APIs for our custom software at Finlogy Inc. We appreciate your efforts. Here's our feedback: Ensure smooth integration, provide comprehensive documentation, prioritize scalability and reliability, implement robust security measures, maintain flexibility for updates, and include a feedback mechanism. Your attention to these aspects will greatly enhance the effectiveness and usability of the APIs. We value your continued support.",
    },
  ];
  
function commonContent3(){
    return(
        <div className="bulediv">
            <div className="no-of-clients">100+ leading business</div>
            <TestimonialSlider testimonials={testimonials} />
        </div>
    )
}
export default commonContent3;