import Header from "../Header/HeaderComponent";
import Footer from "../Footer/FooterComponent";
import "./AboutComponent.scss";
import Missionimg from "../asset/img/mission.svg";
import Visionimg from "../asset/img/vison.svg";
import Valuesimg from "../asset/img/values.svg";
import Homeicon from "../asset/img/Home.svg";
import Thread1 from "../asset/img/thread2.svg";
import Thread2 from "../asset/img/thread1.svg";
import Rightarrow from "../asset/img/Arrow - Right 2.svg";
import Teamprajnanpic from "../asset/img/Team Prajnan pic.jpeg";
import { Helmet } from "react-helmet";
import programpc from "../asset/img/programmingpc.jpg";
import { useContext } from "react";
import { BaseContext } from "../baseContext/BaseContext";
import Waviy from "../smallcomponents/Textanimation/Waviy";
import ImageGrid from "../smallcomponents/Imagegrid/ImageGrid";
import image1 from "../asset/img/Quality.svg";
import image2 from "../asset/img/creative design.svg";
import image3 from "../asset/img/innovation.svg";
import image4 from "../asset/img/team work.svg";
import image5 from "../asset/img/collobration.svg";
import TeamUIUX from "../asset/img/team uiux.svg";
import TeamBackend from "../asset/img/Teambackend.svg";
import TeamVGM from "../asset/img/Teamvideoediting.svg";
import TeamAndroidIOS from "../asset/img/TeamAndroid.svg";
import TeamHRM from "../asset/img/TeamHRMarketing.svg";
import TeamWeb from "../asset/img/Team web.svg";
import Team from "../smallcomponents/TeamSlider/Team";

const images = [image1, image2, image3, image4, image5];
const members = [
    { image: TeamUIUX, name: 'UX/UI design' },
    { image: TeamAndroidIOS, name: 'Android & IOS Development' },
    { image: TeamVGM, name: 'Video Editing & Graphic Design' },
    { image: TeamHRM, name: 'Human Resource & Marketing' },
    { image: TeamBackend, name: 'Backend Development' },
    { image: TeamWeb, name: 'Web Development' },
  ];
function About(){
    const {
        handleLoad,
        loading
      } = useContext(BaseContext);
      
    return(
        <>
            <Helmet>
                <title>Learn About Our Digital Services | Prajnan Software Pvt Ltd</title>
            </Helmet>
            <Header></Header>
            <div className="aboutusbodycontent">
                <div className="homemenu">
                <a href="/home"><img src={Homeicon} alt="Homeicon" /></a>
                    <img src={Rightarrow} alt="rightarrow" />
                    <span className="text">About Us</span>
                </div>
                <div className="tagname">
                    <div> We are </div><Waviy text="Prajnanians" />
                </div>
                <div className="teampic">
                    <img src={Teamprajnanpic} alt="Prajnan team" />
                </div>
                <div className="aboutcontent">
                    <div className="aboutheading">Who we are</div>
                    <div className="abouttext">We are team of passionate designers and developers who love to create beautiful and functional websites and apps. We are committed to delivering excellence in every project we undertake, using the best practices and technologies in the industry.</div>
                </div>
                <div className="aboutimagesdiv">
                    <div className="aboutimagefirstrow">
                        <div className="curved-background-mission">
                            <img src={Missionimg} alt="Mission-image"></img>
                            <div className="aboutimgtext">
                            <div className="about-head">Mission</div>
                            <div className="about-text">Our mission is to provide innovative, reliable, and cost-effective software solutions that empower our clients to achieve their business goals and transform their digital experiences.</div>
                            </div>
                        </div>
                        <div className="curved-background-Vision">
                        <img src={Visionimg} alt="Mission-image"></img>
                        <div className="aboutimgtext">
                        <div className="about-head">Vision</div>
                        <div className="about-text">Our vision is to become a leading software services company that is recognized for its quality, creativity, and customer satisfaction. We aim to create a positive impact on the world by solving complex problem.</div>
                        </div>
                        </div>
                    </div>
                    {/* <div className="aboutimagesecondrow">
                        <div className="curved-background-values">
                        <img src={Valuesimg} alt="Mission-image"></img>
                        <div className="aboutimgtext">
                            <div className="about-head">Values</div>
                            <div className="about-text">Quality<br></br>Creativity<br></br>Innovation<br></br>Team Work<br></br>Collaborative</div>
                        </div>
                        </div>
                    </div> */}
                </div>
                <div className="prajnan-values">
                    <div className="values-header">
                    what we value
                    </div>
                    <ImageGrid images={images} />
                </div>
                {/* <div className="aboutheading">What we do</div> */}
                {/* <div className="abouttext">We offer a range of services to suit your needs, such as:
                    <ul>
                    <li>Website design and development</li>
                    <li>Mobile app design and development </li>
                    <li>UI/UX design </li>
                    <li>Graphic design </li>
                    <li>SEO and Digital marketing </li>
                    <li>Social Media marketing </li>
                    <li>Logo generation</li>
                    </ul> 
                </div> */}
                <div className="OURTEAM">
                    <div className="Ourteam-header">
                    Meet our team
                    </div>
                    <Team members={members} />
                </div>
                {/* <div className="about-and-images">
                <div className="aboutleft">
                <div className="aboutheading">Our Team</div>
                <div className="abouttext">At Prajnan, we are proud of our team of highly skilled professionals who are dedicated to excellence in software development. Our team consists of experienced software engineers, designers, and project managers who work collaboratively to deliver exceptional results for our clients.</div>
                <div className="aboutheading">Contact Us</div>
                <div className="abouttext">Ready to take your business to the next level? Contact us today to learn more about how Prajnan can help you achieve your goals.<a href="/contactus">  Click here...</a></div>
            
                    </div>
                    <div className="aboutright">
                        {loading && (
                            <div className="loadimg">
                                <div className="loader"></div>
                            </div>
                        )}
                        <img src={programpc} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} alt="monitor-pc"></img>
                    </div>
                </div> */}
               </div>
            <Footer></Footer>
        </>
    )
}
export default About;