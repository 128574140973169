import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarRating from './starComponent'; 

interface TestimonialProps {
  profilePic: string;
  name: string;
  location: string;
  rating: number;
  text: string;
}


const Testimonial: React.FC<TestimonialProps> = ({ profilePic, name, location, rating, text }) => {
  return (
    <div className="testimonial" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      textAlign: 'center',
      padding: '1rem',
    }}>
      <img src={profilePic} alt="Profile" className="profile-pic" />
      <p className="name" style={{
        fontWeight: '700',
        margin: '0.3rem'
      }}>{name}</p>
      <p style={{
        margin:'0',
        fontSize: '0.7rem',
      }}>{location}</p>
      <div className="rating">
        <StarRating rating={rating} />
      </div>
      <p className="text">{text}</p>
    </div>
  );
};

const TestimonialSlider: React.FC<{ testimonials: TestimonialProps[] }> = ({ testimonials }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div key={index}>
          <Testimonial {...testimonial} />
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialSlider;
