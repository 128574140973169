import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route }
    from "react-router-dom";
import './App.css';
import Home from './Home/HomeComponent';
import About from './About/AboutComponent';
import Contactus from './ContactUs/contactUsComponent';
import Careers from './Careers/CareersComponent';
import NotFound from './ErrorPages/NotFoundComponent';
import { BaseContextProvider } from './baseContext/BaseContextProvider';
import Modal from './Signin Signup/Model';
import FloatingScrollButton from './smallcomponents/buttons/FloatingButton/FloatingScrollButton';
import { Helmet } from 'react-helmet';
import Service from './Services/ServicemainComponent';
import '@fontsource/montserrat';
import './index.css';
import WebFont from 'webfontloader';
import WebService from './Services/webservice/WebServiceComponent';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:400,700,600,500'] 
      }
    });
  }, []);
  return (
    <BaseContextProvider>
         <Router>
                <Helmet>
                    <title>Transforming Your Business Digitally | Prajnan Software Pvt Ltd</title>
                </Helmet>
          <Routes>
              <Route path="/"
                element={<Home />} />
              <Route path="/home"
                element={<Home />} />
              <Route path="/service"
                element={<Service />} />
              <Route path="/aboutus"
                element={<About />} />
              <Route path="/contactus"
                element={<Contactus />} />
              <Route path="/Careers"
                element={<Careers />} />
              <Route path="/webservice"
                element={<WebService />} />
              <Route path="*"
                element={<NotFound />} />
          </Routes>
      </Router>
      <Modal></Modal>
      <FloatingScrollButton></FloatingScrollButton>
    </BaseContextProvider>
  );
}

export default App;
