// PositionPage.tsx
import React from 'react';
import './PositionPage.scss';

interface PositionPageProps {
  title: string;
  responsibilities: string[];
  eligibility: string[];
  roles: string[];
  googleFormLink: string;
}

const PositionPage: React.FC<PositionPageProps> = ({
  title,
  responsibilities,
  eligibility,
  roles,
  googleFormLink,
}) => {
  const openGoogleForm = () => {
    window.open(googleFormLink, '_blank');
  };

  return (
    <div className="position-page">
      <h1 className="title">{title}</h1>
      <div className="cards-container">
        <div className="card">
          <h2 className="card-title">Responsibilities</h2>
          <ul className="card-list">
            {responsibilities.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="card">
          <h2 className="card-title">Eligibility</h2>
          <ul className="card-list">
            {eligibility.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="card">
          <h2 className="card-title">Roles</h2>
          <ul className="card-list">
            {roles.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <button className="apply-button" onClick={openGoogleForm}>
        Apply Now
      </button>
    </div>
  );
};

export default PositionPage;
