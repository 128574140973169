import React from 'react';
import './NotFoundComponent.scss'; 
import Notfound from "../asset/img/404 error.svg";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Oops! Page Not Found</h1>
      <img src={Notfound} ></img>
      <p className="not-found-message">
        We sent our robots to search for this page, but they couldn't find it.
        <br />
        Let's go back to the <a href="/">home page</a> and try again!
      </p>
    </div>
  );
};

export default NotFound;