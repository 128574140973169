import React, { useContext } from "react";
import './HomeComponent.scss';
import Header from "../Header/HeaderComponent";
import Footer from "../Footer/FooterComponent";
import illustration from "../asset/img/illustration.svg";
import mobilecode from "../asset/img/mobile-coding.png";
import ux from "../asset/img/ux.png";
import Product from "../asset/img/product-management.png";
import HomeBlackimage from "../asset/img/homepageblackimg.svg";
import CC1 from "../smallcomponents/commonComponent/commonContent1";
import CC2 from "../smallcomponents/commonComponent/commonContent2";
import Getstart from "../smallcomponents/commonComponent/GetstartedComponent";
import CC3 from "../smallcomponents/commonComponent/commonComponent3";
import { BaseContext } from "../baseContext/BaseContext";
import BlackBox from "../smallcomponents/BlackBox/BlackBoxComponent";
import { useInView } from 'react-intersection-observer';

export default function Home(){
    const {
        loading,
        handleLoad
    } = useContext(BaseContext);
    const { ref, inView } = useInView({
        // triggerOnce: true, // Trigger the animation only once
        threshold: 0.5, // Trigger when 50% of the card is in view
      });
    return(
    <>
    <Header></Header>
    <div className="homepage">
        <div className="body">
        <div className="homecontent">
            {/* <div className="taglines">
                <span>"ಸ್ವಾಗತ! ನವೀಕರಣಾತ್ಮಕ ಸಾಫ್ಟ್‌ವೇರ್ ಪರಿಸರ." -- "Welcome! Innovative Software Environment." -- "ನಮ್ಮ ಪ್ರಜ್ಞಾನ್ ಸಾಫ್ಟ್‌ವೇರ್: ನಿಮ್ಮ ಕನಸುಗಳ ಸಾಕಾರ!"</span>
            </div> */}
            <h3>Grow Your Business To New Heights <br></br> With Our <span> Digital Services</span> </h3>
            <div className="getstart">
                <a href="#maindiv">Get Started</a>
            </div>
            <div className="illustration">
                {
                   loading && ( <span className="loaderspin"></span>)
                }
                <img src={illustration} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} alt="work_img"></img>
                {/* <video loop autoPlay muted>
        <source src={Welcomevideos} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
            </div>
            <div className="cardscontainer">
            <a href="/webservice" ref={ref} className={`cards ${inView ? 'animate' : ''}`}>
                <div className="logo">
                        <img src={ux} alt="desktop"></img>
                    </div>
                    <div className="content">
                        <h2>Web development</h2>
                        {/* <p>Web development can range from developing a static page of plain text to complex web-based applications.</p> */}
                    </div>
                </a>
                <div ref={ref} className={`cards ${inView ? 'animate' : ''}`}>
                    <div className="with-offer">
                        <div className="ribbon"><span>Coming Soon</span></div>
                    </div>
                    <div className="logo">
                        <img src={Product} alt="megaphone"></img>
                    </div>
                    <div className="content">
                        <h2>Our Products</h2>
                        {/* <p>PSLabour hub<br></br>PSLabour champ<br></br>PSAgri mart</p> */}
                    </div>
                </div>
                <div ref={ref} className={`cards ${inView ? 'animate' : ''}`}>
                    <div className="logo">
                        <img src={mobilecode} alt="megaphone"></img>
                    </div>
                    <div className="content">
                        <h2>Android and IOS app development</h2>
                        {/* <p>Everyone is on mobile devices. It's easy to get your own app now.</p> */}
                    </div>
                </div>
            </div>
            <CC1></CC1>
            <CC2></CC2>
            <Getstart></Getstart>
            <BlackBox buletext={"Want to accelerate software development at your company ?"} orangetext={"See how we can help."} imageUrl={HomeBlackimage}></BlackBox>
            <CC3></CC3>
        </div>
        <Footer></Footer>
        </div>
    </div>
    </>
    )
}

