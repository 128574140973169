import { useContext } from "react";
import { BaseContext } from "../../baseContext/BaseContext";
import { Helmet } from "react-helmet";
import './WebServiceComponent.scss';
import Header from "../../Header/HeaderComponent";
import Footer from "../../Footer/FooterComponent";
import Homeicon from "../../asset/img/Home.svg";
import Rightarrow from "../../asset/img/Arrow - Right 2.svg";
import BlackBox from "../../smallcomponents/BlackBox/BlackBoxComponent";
import Webserviceimage from "../../asset/img/webserviceblackimage.svg";
import Webserviceimage2 from "../../asset/img/webserviceimage2.svg";
import WebserviceGif from "../../asset/videos/Animated-iPad-mockup-remix-ezgif.com-optimize.gif";
import Step1Icon from "../../asset/img/step1icon.svg";
import Step2Icon from "../../asset/img/step2icon.svg";
import Step3Icon from "../../asset/img/step3icon.svg";


export default function WebService(){
    const {
        loading,
        handleLoad
    } = useContext(BaseContext);

    return(
        <>
          <Helmet>
                <title>Our Services: Digital Transformation, Web Development, and More | Prajnan Software Careers</title>
          </Helmet>  
          <Header></Header>
          <div className="webservicemaincontainer">
            <div className="homemenu">
                <a href="/home"><img src={Homeicon} alt="Homeicon" /></a>
                <img src={Rightarrow} alt="rightarrow" />
                <a href="/service" className="text">Services</a>
                <img src={Rightarrow} alt="rightarrow" />
                <span className="text">Website development</span>
            </div>
            <div className="webservicetopdiv">
                <div className="webserviceleftdiv">
                    <div className="webservicetitle">WEBSITE DEVELOPMENT</div>
                    <div className="webservicheadcontent">Quality <span>web services</span> for everyone's online presence</div>
                </div>
                <div className="webserviceRightdiv">
                    <img src={WebserviceGif} alt="GIF" />
                </div>
            </div>
            <div className="webservicecontent">
                <div className="webserviceheading">Website development service</div>
                <div className="webservicetext">At Prajnan Software, we specialize in crafting bespoke web solutions tailored to meet your unique needs and exceed your expectations. With a team of experienced developers and designers, we're committed to delivering high-quality websites that not only look stunning but also perform seamlessly across all devices.</div>
                <div className="webserviceheading">Our Approach</div>
                <div className="webservicetext">Our approach to web development revolves around collaboration, innovation, and attention to detail. We work closely with you to understand your business objectives, target audience, and brand identity, ensuring that every aspect of your website aligns with your goals.</div>
                <div className="webserviceheading2">Services We Offer</div>
                <div className="webserviceheading">1. Custom Website Development: <span className="webservicetext">Whether you need a simple informational website or a complex web application, we have the expertise to bring your vision to life. Our custom web development services ensure that your website stands out from the competition and delivers an exceptional user experience.</span></div>
                <div className="webserviceheading">2. Responsive Design: <span className="webservicetext"> In today's mobile-driven world, it's essential for your website to be fully responsive and accessible across all devices. We design and develop responsive websites that adapt seamlessly to various screen sizes, ensuring that your audience can access your content anytime, anywhere.</span></div>
                <div className="webserviceheading">3. E-commerce Solutions: <span className="webservicetext"> Looking to sell your products or services online? Our e-commerce solutions are designed to help you maximize your online sales potential. From user-friendly shopping carts to secure payment gateways, we'll create an e-commerce platform that drives conversions and boosts your bottom line.</span></div>
                <div className="webserviceheading">4. Content Management Systems (CMS): <span className="webservicetext">  Take control of your website with our intuitive content management systems. Whether you prefer WordPress, Joomla, Drupal, or a custom-built CMS, we'll empower you to easily manage and update your website's content without any technical expertise.</span></div>
                <div className="webserviceheading">5. Website Maintenance and Support: <span className="webservicetext">Our commitment to your success doesn't end with the launch of your website. We provide ongoing maintenance and support services to ensure that your website remains secure, up-to-date, and optimized for peak performance.Why Choose Us?</span></div>
                <div className="webserviceheading">Expertise: <span className="webservicetext">Our team of seasoned developers and designers has the skills and knowledge to tackle projects of any size or complexity.</span></div>
                <div className="webserviceheading">Creativity: <span className="webservicetext">We're passionate about pushing the boundaries of design and technology to create websites that leave a lasting impression.</span></div>
                <div className="webserviceheading">Reliability: <span className="webservicetext">With a proven track record of delivering results on time and within budget, you can trust us to handle your web development needs with professionalism and integrity.</span></div>
                <a href="/contactus" className="Contactlink">Get Started Today!</a>
            </div>
            <div className="webservicecontent2">
            <div className="webservicecontent2left">
                <div className="webservicecontent2lefttext">What is the process of getting the project</div>
                <div className="webservicecontent2leftimage">
                    <img src={Webserviceimage2} alt="Webserviceimage2s" />
                </div>
            </div>
            <div className="webservicecontent2right">
                <div className="step">
                <div className="stepimgdiv"><img src={Step1Icon} alt="" /></div>
                    <div className="steptext">
                        <div className="stepheading">Step 1 : Give your requirements </div>
                        <div className="stepbodytext">Tell us what are all the requirements of the project for the frontend development, give us the budget, timeline & goal.</div>
                    </div>
                </div>
                <div className="step">
                <div className="stepimgdiv"><img src={Step2Icon} alt="" /></div>
                    <div className="steptext">
                    <div className="stepheading">Step 2 : we’ll create a plan </div>
                        <div className="stepbodytext">At the first step before developing we’ll do planning and collaborate with the designers and other team members.</div>
                    </div>
                </div>
                <div className="step">
                    <div className="stepimgdiv"><img src={Step3Icon} alt="" /></div>
                    <div className="steptext">
                    <div className="stepheading">Step 3 : Start work </div>
                        <div className="stepbodytext">We’ll start work by doing research and match with the current trends in the market and we will try to adapt current trends with the product.</div>
                    </div>
                </div>
                <a href="/contactus"><div className="callbutton">Schedule a call</div></a>
            </div>
            </div>
          </div>
          <BlackBox buletext={"Looking for web development services ? "} orangetext={"See how we can help you"} imageUrl={Webserviceimage}></BlackBox>
          <Footer></Footer>
        </>
    )
}