import React, { useContext, useState } from 'react';
import './SignUp.scss'; // SCSS for SignUp component
import { BaseContext } from '../baseContext/BaseContext';

const SignUp = (props:any) => {
  
  const{
    setIsModalOpen
  } = useContext(BaseContext);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  // const validatePassword = (password: string) => {
  //   // Password validation: at least 8 characters
  //   return password.length >= 8;
  // };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const email = formData.get('email') as string;
  const password = formData.get('password') as string;
  const confirmPassword = formData.get('confirmPassword') as string;
    // Validation
    
    const newErrors: { email?: string; password?: string; confirmPassword?: string } = {};

    if (!email || !validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!password || password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length === 0) {
      // If there are no errors, proceed with form submission
      fetch('SaveUserData.php', {
        method: 'POST',
        body: formData,
      })
      .then(response => response.text())
      .then(data => {
        console.log(data); // Log the response from PHP
        // Handle success or display a message to the user
       if(data.includes('email already exists.')){
        alert('email id already exists.')
       }else{
        alert("sign up successfully");
        setFormData({
          email: '',
          password: '',
          confirmPassword:''
        });
        setIsModalOpen(false);
       }
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error case
      });
    } else {
      // If there are errors, update the errors state to show them in the form
      setErrors({
        ...errors,
        ...newErrors,
      });
    }
  };
 
  return (
    <form className="auth-form" onSubmit={handleSubmit} action="SaveUserData.php" method="POST">
      <input
        type="email"
        name='email'
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
      <input
        type="password"
        name='password'
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
        required
      />
      {errors.password && (
            <span style={{ color: 'red' }}>{errors.password}</span>
          )}

      <input
        type="password"
        name='confirmPassword'
        placeholder="Confirm Password"
        value={formData.confirmPassword}
        onChange={handleChange}
        required
      />
      {errors.confirmPassword && (
            <span style={{ color: 'red' }}>{errors.confirmPassword}</span>
          )}
      <button type="submit">Sign Up</button>
    </form>
  );
};

export default SignUp;
