import { useContext } from "react";
import { BaseContext } from "../baseContext/BaseContext";
import { Helmet } from "react-helmet";
import Header from "../Header/HeaderComponent";
import Footer from "../Footer/FooterComponent";
import Homeicon from "../asset/img/Home.svg";
import Rightarrow from "../asset/img/Arrow - Right 2.svg";
import Serviceimage1 from "../asset/img/serviceimg1.svg";
import Serviceimage2 from "../asset/img/serviceimg2.svg";
import orangeRightArrow from "../asset/img/orange right arrow.svg";
import Targeticon from "../asset/img/targeticon.svg";
import EcommerceImage from "../asset/img/e-commerceimage.svg";
import WebDevelopmentImage from "../asset/img/webdevlopentimage.svg";
import GraphicImage from "../asset/img/graphicdesign.svg";
import UIUXImage from "../asset/img/uiuximage.svg";
import CustomSoftwareImage from "../asset/img/customsoftware image.svg";
import DigitalmarketingImage from "../asset/img/digitalmarketing.svg";
import AndroidDevlopmentImage from "../asset/img/Android-Ios-Image.svg";
import './ServicemainComponent.scss';
import DualMarquee from "../smallcomponents/Marquee/Dualmarquee";
import BlackBox from "../smallcomponents/BlackBox/BlackBoxComponent";

export default function Service(){
    const {
        loading,
        handleLoad
    } = useContext(BaseContext);

    return(
        <>
          <Helmet>
                <title>Our Services: Digital Transformation, Web Development, and More | Prajnan Software Careers</title>
          </Helmet>  
          <Header></Header>
          <div className="servicesmainpage">
            <div className="topdiv">
                <div className="homemenu">
                <a href="/home"><img src={Homeicon} alt="Homeicon" /></a>
                    <img src={Rightarrow} alt="rightarrow" />
                    <span className="text">Services</span>
                </div>
                <div className="intromaindiv">
                    <div className="servicehead">
                        <div className="servicet1">SOFTWARE DEVELOPMENT SOLUTIONS</div>
                        <div className="servicet2">Tap into our end - to-end <br></br><span>Tech solutions.</span> </div>
                    </div>
                    <div className="serviceimage1">
                    {loading && (
                            <div className="loadimg">
                                <div className="loader"></div>
                            </div>
                        )}
                        <img src={Serviceimage1} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} alt="Serviceimage1" />
                    </div>
                </div>
            </div>
            <div className="servicesmaindiv">
                <div className="c1">
                    <div className="c1r1">
                        <div className="servicetitle">
                            <img src={Targeticon} alt="Target-icon" />
                            <span>Services we offer</span>
                        </div>
                        <div className="serviceintro">Our expertise lies in delivering custom software services that elevate your brand, enhance user experiences, and drive business growth.</div>
                    </div>
                    <div className="c1r2 servicecard">
                        <div className="servicename">E - Commerce solution</div>
                        <div className="servicedetail">Owning a digital store was never this easy.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={EcommerceImage} alt="ecommerce-image" />
                        </div>
                    </div>
                </div>
                <div className="serviceflexC">
                <div className="c2">
                    <div className="servicecard">
                    <div className="servicename">Web development</div>
                        <div className="servicedetail">Quality web services for everyone's online presence.</div>
                        <a href="/webservice" className="learnmore">Learn More<img src={orangeRightArrow}></img></a>
                        <div className="serviceimagediv">
                            <img src={WebDevelopmentImage} alt="WebDevelopment-image" />
                        </div>
                    </div>
                    <div className="servicecard">
                    <div className="servicename">Android & IOS development</div>
                        <div className="servicedetail">Everyone is on mobile devices. It's easy to get your own app now.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={AndroidDevlopmentImage} alt="AndroidDevlopment-Image" />
                        </div>
                    </div>
                    <div className="servicecard">
                    <div className="servicename">Graphic design & video editing</div>
                        <div className="servicedetail">Branding solutions for your dream project.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={GraphicImage} alt="Graphic-image" />
                        </div>
                    </div>
                </div>
                <div className="c3">
                    <div className="servicecard">
                    <div className="servicename">UI/UX design</div>
                        <div className="servicedetail">Design matters! We're the digital artists for your needs.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={UIUXImage} alt="UIUX-image" />
                        </div>
                    </div>
                    <div className="servicecard">
                    <div className="servicename">Digital marketing</div>
                        <div className="servicedetail">We make your digital presence to be found online.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={DigitalmarketingImage} alt="Digitalmarketing-image" />
                        </div>
                    </div>
                    <div className="servicecard">
                    <div className="servicename">Custom Software</div>
                        <div className="servicedetail">We can understand your software needs better.</div>
                        <div className="learnmore">Learn More<img src={orangeRightArrow}></img></div>
                        <div className="serviceimagediv">
                            <img src={CustomSoftwareImage} alt="CustomSoftware-image" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="techdiv">
                <div className="heading">What are tools & technologies we use</div>
                <div className="subheading">We are well-versed in most programming languages, tools, and frameworks.</div>
                <div className="marquee">
                <DualMarquee leftText="Figma &emsp; Node.js &emsp; React &emsp; Java &emsp; C++ &emsp; C# &emsp; Javascript &emsp; Angular &emsp; PHP &emsp; Python &emsp; SQL &emsp; IOS &emsp; Android &emsp; Figma &emsp; Node.js &emsp; React &emsp; Java &emsp; C++ &emsp; C# &emsp; Javascript &emsp; Angular &emsp; PHP &emsp; Python &emsp; SQL &emsp; IOS &emsp; Android &emsp;" rightText="Javascript &emsp; Angular &emsp; PHP &emsp; Python &emsp; SQL &emsp; IOS &emsp; Android &emsp; Figma &emsp; Node.js &emsp; React &emsp; Java &emsp; C++ &emsp; C# &emsp; Javascript &emsp; Angular &emsp; PHP &emsp; Python &emsp; SQL &emsp; IOS &emsp; Android &emsp; Figma &emsp; Node.js &emsp; React &emsp; Java &emsp; C++ &emsp; C# &emsp; " />
                </div>
            </div>
            <BlackBox buletext={"We are moving on with the technology come let us make some innovation with the "} orangetext={"Tech"} imageUrl={Serviceimage2}></BlackBox>
          </div>
          <Footer></Footer>
        </>
    )
}