import React, { useRef, useEffect } from 'react';
import './Dualmarquee.scss'; // Assuming you have a SCSS file for styling

interface DualMarqueeProps {
  leftText: string;
  rightText: string;
}

const DualMarquee: React.FC<DualMarqueeProps> = ({ leftText, rightText }) => {
  const leftMarqueeRef = useRef<HTMLDivElement>(null);
  const rightMarqueeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const moveLeft = () => {
      if (leftMarqueeRef.current) {
        const containerWidth = leftMarqueeRef.current.offsetWidth;
        const contentWidth = leftMarqueeRef.current.scrollWidth;
        const duration = (contentWidth / containerWidth) *  3 ; // Adjust speed
        leftMarqueeRef.current.style.animation = `marquee-left ${duration}s linear infinite`;
      }
    };

    const moveRight = () => {
      if (rightMarqueeRef.current) {
        const containerWidth = rightMarqueeRef.current.offsetWidth;
        const contentWidth = rightMarqueeRef.current.scrollWidth;
        const duration = (contentWidth / containerWidth) *  3 ; // Adjust speed
        rightMarqueeRef.current.style.animation = `marquee-right ${duration}s linear infinite`;
      }
    };

    moveLeft();
    moveRight();
  }, [leftText, rightText]);

  return (
    <div className="dual-marquee">
      <div className="left-marquee" ref={leftMarqueeRef}>
        {leftText}
      </div>
      <div className="right-marquee" ref={rightMarqueeRef}>
        {rightText}
      </div>
    </div>
  );
};

export default DualMarquee;
