import React from "react";
import "./commonContent.scss";
import cc2 from "../../asset/img/cc2.svg";

function commonContent2(){
    return(
        <div className="commoncontentbox">
            <div className="text"> 
                <h2>Why Choose <span>Prajnan Software?</span></h2>
                <p>If you are looking for a reliable and quality IT service provider, search no more . We are here to cater each and every customer to their full satisfaction and come true to our clients expectation. Customised and personal touch is our strength. With our passion for tech , we enjoy giving the best to our clients based on their need and industry requirement.</p>
            </div>
            <div className="image">
                <img src={cc2} alt="illu"></img>
            </div>
        </div>
    )
}
export default commonContent2;