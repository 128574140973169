import React, { useContext, useEffect, useState } from "react";
import Logo from "../asset/img/Logo1.png";
import "./HeaderComponent.scss";
// import Title from "../asset/img/Title-name.png";
import user from "../asset/img/profile.svg";
import Menu from "../asset/img/menu-icon.png";
import { BaseContext } from "../baseContext/BaseContext";
import { useLocation } from 'react-router-dom';
import ProfileModal from "../ProfileModal/ProfileModal";
function Header(props:any){
      // Get the current location
  const location = useLocation();

  // Define a function to check if the current path is the home path
  const isHomePath = (route: string) => location.pathname === '/home';
  const isAboutPath = (route: string) => location.pathname === '/aboutus';
  const isCareerPath = (route: string) => location.pathname === '/careers';
  const isContactPath = (route: string) => location.pathname === '/contactus';
  const isServicePath = (route: string) => location.pathname === '/service' || location.pathname === '/webservice';
  
  // Apply a different style if the current path is the home path
  const getHomeActiveStyle = (route: string) => ({
    color: isHomePath(route) ? 'orangered' : '#013979',
  });
  const getContactActiveStyle = (route: string) => ({
    color: isContactPath(route) ? 'orangered' : '#013979',
  });
  const getCareerActiveStyle = (route: string) => ({
    color: isCareerPath(route) ? 'orangered' : '#013979',
  });
  const getAboutActiveStyle = (route: string) => ({
    color: isAboutPath(route) ? 'orangered' : '#013979',
  });
  const getServiceActiveStyle = (route: string) => ({
    color: isServicePath(route) ? 'orangered' : '#013979',
  });
    const {
        openModal,
        setIsSignIn,
        isSignIn,
        isLogin,
        openProfile,
    } = useContext(BaseContext);
    const handlemenu = (event:any) => {
        console.log("handleclick menu",event.target.id)
    }
    const handleProfile = (e:any) => {
        console.log("click",e);
        
        if (!isLogin) {
            console.log("openmodel");
            openModal();
        }else{
            console.log("openProfile");
            openProfile();
        }
    }
    const [isScrolled, setIsScrolled] = useState(false);
   
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return(
        <>
            <div className={`maincontainer ${isScrolled ? 'scrolled' : ''}`}>
                <div className="leftcontainer">
                  <a href="/home">
                      <div className="logo">
                        <img src={Logo} alt="logo" width={50} height={30}/>
                    </div>
                    <div className="title typing">
                        PRAJNAN<br></br><span>SOFTWARE</span>
                    </div>
                  </a>
                </div>
                <div className="middlecontainer">
                    <nav>
                        <ul>
                            <li><a href="/home" style={getHomeActiveStyle('/home')}>Home</a></li>
                            <li><a href="/service" style={getServiceActiveStyle('/service')}>Services
                                {/* <div className="dropdown">
                                    <ul>
                                        <li>Web Services</li>
                                        <li>- Website Development</li>
                                        <li>- Website Designing</li>
                                        <li>- Responsive Website Designing</li>
                                        <li>- Custom Website Designing</li>
                                        <li>- Ecommerce Website</li>
                                        <li>- Static Website Designing</li>
                                        <li>- Dynamic Website Designing</li>
                                        <li>UI/UX design and development</li>
                                        <li>Logo and brand design kit for new businesses</li>
                                        <li>Digital Marketing</li>
                                        <li>Search Engine Optimization</li>
                                        <li>E-commerce solution</li>
                                        <li>Social media marketing</li>
                                        <li>Custom Software</li>
                                        <li>Lead generation service</li>
                                        <li>Company Registration</li>
                                    </ul>
                                </div> */}
                            </a></li>
                            <li><a href="/aboutus" style={getAboutActiveStyle('/aboutus')}>About Us</a></li>
                            <li><a href="/careers" style={getCareerActiveStyle('/careers')}>Careers</a></li>
                            <li><a href="/contactus" style={getContactActiveStyle('/contactus')}>Contact Us</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="rightcontainer">
                    <div className="mailbtncontainer">
                    <div className="mailbutton">
                        <a href="/contactus" style={getContactActiveStyle('/contactus')}>
                        {/* <span className="icon">
                            <img className="svgmail" src={Mail} alt="mailicon" ></img>
                        </span> */}
                        <span className="text">Get a Free Quote</span></a>
                    </div>
                    </div>
                    {/* <div className="signup-signin" onClick={handleProfile}>
                        <img src={user}></img>
                        <ProfileModal></ProfileModal>
                    </div> */}
                    <div className="menuContainer">
                        <img src={Menu} alt="menu icon" id="menu" onClick={handlemenu}></img>
                        <div className="menulist">
                            <ul>
                            <li><a href="/home">Home</a></li>
                            <li><a href="/service" className="options">Services</a></li>
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/Careers">Careers</a></li>
                            <li><a href="/contactus">Contact Us</a></li>
                            </ul>
                        </div>
                </div>
                </div>
                
            </div>
            
        </>
    )
}
export default Header;