import React, { useState } from "react";
import "./ContactUsComponent.scss";
import Header from "../Header/HeaderComponent";
import Footer from "../Footer/FooterComponent";
import { Helmet } from "react-helmet";

function Contactus(){

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        subject: '',
      });
    
      const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e:any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
      
        fetch('submit_form.php', {
          method: 'POST',
          body: formData,
        })
        .then(response => response.text())
        .then(data => {
          console.log(data); // Log the response from PHP
          // Handle success or display a message to the user
          alert("Message sent successfully");
          setFormData({
            name: '',
            email: '',
            message: '',
            subject: '',
          });
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle error case
        });
      };
      
    return(
        <>
            <Helmet>
                <title>Contact Prajnan Software for Digital Solutions | Prajnan Software Careers</title>
            </Helmet>
            <Header></Header>
            <div className="contactusbodycontent">
                <h1>Let's talk</h1>
                <p>have some great idea or brand to develop? Let's <br></br>build it together</p>
                <div className="contactSection">
                    <div className="contactbody">
                    <div className="contactDetails">
                        <div className="contactbox">
                            <div className="text">
                                <div className="contactHead">Main Branch</div>
                                <div className="contactNormal">#Banashankari road, near Sriram temple, Ajjampura, Karnataka 577547</div>
                            </div>
                        </div>
                        <div className="contactbox">
                            <div className="text">
                                <div className="contactHead">Bengaluru Branch</div>
                                <div className="contactNormal">#30/1 Patel Muniyappa Layout,R T Nagar, Hebbal, Bengaluru, Karnataka 560032</div>
                            </div>
                        </div>
                        <div className="contactbox">
                            <div className="text">
                                <div className="contactHead">Phone Number</div>
                                <div className="contactNormal">+91 7022478822</div>
                            </div>
                        </div>
                        <div className="contactbox">
                            <div className="text">
                                <div className="contactHead">Email ID</div>
                                <div className="contactNormal">contactus@prajnansoftware.com</div>
                            </div>
                        </div>
                    </div>
                    <div className="formSection">
                        <form className="contactform" action="submit_form.php" onSubmit={handleSubmit} method="POST">
                            <div className="name">
                                <label>Full Name</label>
                                <input type="text" name="name" value={formData.name}
        onChange={handleChange} required></input>
                                <div className="contactFormLine"></div>
                            </div>
                            <div className="email">
                            <label>Email ID</label>
                                <input type="email" name="email" value={formData.email}
        onChange={handleChange} required></input>
                                <div className="contactFormLine"></div>
                            </div>
                            <div className="message">
                            <label>Subject</label>
                                <input type="text" name="subject" value={formData.subject}
        onChange={handleChange} required></input>
                                <div className="contactFormLine"></div>
                            </div>
                            <div className="textbox">
                            <label>Message</label>
                                <input name="message" value={formData.message}onChange={handleChange}></input>
                                <div className="contactFormLine"></div>
                            </div>
                            <div className="submit-button">
                                 <button type="submit" value="Submit"> Submit</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5539356174922!2d77.59629387327792!3d13.06404078725989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19003059fbcb%3A0x85d811ee931d0e81!2sPrajnan%20software!5e0!3m2!1sen!2sin!4v1709408695697!5m2!1sen!2sin"
                width="100%" 
                height="450" 
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy" 
            ></iframe>
            <Footer></Footer>
        </>
    )
}
export default Contactus;